.scroll-horizontal {
    overflow-x: auto;
    overflow-y: hidden;
}
.scroll-vertical {
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-medium {
    max-height: 340px;
}
.min-height-medium {
    min-height: 240px;
}

.text-nowrap {
    white-space: nowrap;
}
.no-deco {
    text-decoration: none!important;
}

.w-100 {
    width: 100%;
}

.indent-1 {
    margin-left: 1rem;
}
.indent-2 {
    margin-left: 2rem;
}
.indent-3 {
    margin-left: 3rem;
}
.indent-4 {
    margin-left: 4rem;
}
.indent-5 {
    margin-left: 5rem;
}
.indent-6 {
    margin-left: 6rem;
}

.modal-content.full {
    width: 100%;
    padding: 20px;
    height: calc(100vh - 80px);
}
.modal-content.full > .box {
    height: 100%;
}